<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import Preloader from "@/components/preloader";
import ApplicationHeader from "@/components/applications/application-header";
import SidebarDetails from "@/components/applications/sidebar-details";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { roleService } from "@/app/service/roleService";
import Repository from "@/app/repository/repository-factory";
import { appService } from "@/app/service/appService";

const PaymentApplicationRepository = Repository.get(
  "PaymentApplicationRepository"
);
const AcceptorRepository = Repository.get("AcceptorRepository");

export default {
  components: {
    Preloader,
    Multiselect,
    PageHeader,
    Layout,
    ApplicationHeader,
    SidebarDetails,
  },
  data() {
    return {
      itemData: null,
      disabledBtn: false,
      preloader: false,
      options: [],
      newAcceptor: "",
      title: "Zmiana akceptującego wniosek",
      items: [
        {
          text: "Strona główna",
          to: { name: "home" },
        },
        {
          text: "Zmiana akceptującego wniosek",
          active: true,
        },
      ],
    };
  },
  validations: {
    newAcceptor: {
      required,
    },
  },
  async created() {
    const promise = await PaymentApplicationRepository.get(
      this.$route.params.uuid
    );

    let acceptors = null;

    if (
      promise.data.applicationStatus === "Potwierdzone przez wnioskującego" ||
      promise.data.applicationStatus === "Zaakceptowane przez akceptującego wyższego rzędu"
    ) {
      if (
        promise.data.changeDataManually &&
        promise.data.typeOfApplication !== "Przelew skarbowy" &&
        promise.data.applicationStatus === "Potwierdzone przez wnioskującego"
      ) {
        acceptors = await AcceptorRepository.getHigherOrderAcceptors();
      } else if (
        promise.data.typeOfApplication == "Zasilenie karty"
      ) {
        let isSettled = promise.data.applicationSpecificData.prepaidCardSettlement.isSettled;
        let KRSparam = isSettled ? "" : "?onlyKRS=true";
        acceptors = await AcceptorRepository.getAllCardsAcceptors(
          promise.data.companyId,
          KRSparam
        );
      } else {
        acceptors = await AcceptorRepository.getAllAcceptors(
          promise.data.companyId
        );
      }
    }

    this.itemData = promise.data;

    if (acceptors !== null) {
      let filteredOptions = acceptors.data.filter(
        (obj) => obj.userAccountId != this.itemData.acceptorUserAccountId
      );
      this.options = filteredOptions;
    }

  },
  computed: {
    isApplicant() {
      return roleService.isApplicant();
    },
  },
  methods: {
    addNewAcceptor() {
      this.formSubmit();

      if (this.$v.$error === true) {
        return false;
      }

      let payload = {
        acceptorUserAccountId: this.newAcceptor.userAccountId,
        version: this.itemData.version,
      };

      this.disabledBtn = true;
      this.preloader = true;

      PaymentApplicationRepository.changeAcceptor(
        payload,
        this.$route.params.uuid
      )
        .then(() => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES! ",
            text: "Zmieniono akceptującego wniosek!",
            showConfirmButton: false,
            timer: 2000,
            onClose: () => {
              this.$router.push({
                name: "home",
              });
            },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
    formSubmit(e) {
      this.$v.$touch();
    },
    acceptorLabel({ firstName, lastName, email }) {
      return `${firstName} ${lastName} ${email}`;
    },
    compareUserToApplier(user) {
      return user === this.$store.getters["auth/loggedIn"].userAccountId;
    },
    checkStatus(s, a) {
      return appService.checkAppStatus(s, a)
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="title" :items="items" />

    <div class="row" v-if="isApplicant">
      <template v-if="itemData !== null">
        <div class="col-md-8">
          <div class="card shadow">
            <div class="card-body">
              <ApplicationHeader :itemData="itemData" />
              <hr />
              <!-- <b-alert variant="warning" class="mb-3" show fade v-if="itemData.acceptorWasChanged === 0">
                <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Zmiana akceptującego wniosek jest jednorazowa i nieodwracalna!
              </b-alert> -->
              <b-alert variant="danger" class="mb-3" show fade v-if="!isApplicant && !compareUserToApplier(itemData.applicantUserAccountId)">
                <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Nie masz uprawnień do zmiany akceptującego wniosek!
              </b-alert>
              <b-alert variant="warning" class="mb-3" show fade v-if="!compareUserToApplier(itemData.applicantUserAccountId) || !checkStatus(itemData.applicationStatus, [1,8])">
                <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Zmiana akceptującego wniosek nie jest możliwa!
              </b-alert>
              <div class="row" v-if="compareUserToApplier(itemData.applicantUserAccountId) && checkStatus(itemData.applicationStatus, [1,8])">
                <div class="col">
                  <div>
                    <p class="font-weight-bold">
                      Wybierz akceptującego wniosek
                      <span class="text-danger">*</span>
                    </p>
                    <multiselect v-model="newAcceptor" :options="options" :multiple="false" :custom-label="acceptorLabel" track-by="userAccountId" :class="{ 'is-invalid': $v.newAcceptor.$error, 'is-valid': !$v.newAcceptor.$invalid && !$v.newAcceptor.$error }" placeholder="Wybierz akceptującego" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.email }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row my-4" v-if="compareUserToApplier(itemData.applicantUserAccountId) && checkStatus(itemData.applicationStatus, [1,8])">
                <div class="col d-flex justify-content-between">
                  <router-link to="/" tag="button" class="wizzard-btn">Powrót</router-link>
                  <template v-if="isApplicant && compareUserToApplier(itemData.applicantUserAccountId)">
                    <button class="ml-2 wizzard-btn" v-on:click="addNewAcceptor" :disabled="$v.$invalid || disabledBtn" type="submit">Zmień</button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card shadow">
            <SidebarDetails :itemData="itemData" />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="card-body">
          <div class="row px-2 py-3 mx-0" style="border:1px solid #ccc">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <preloader></preloader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </Layout>
</template>

<style lang="scss">
.wizzard-btn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  line-height: 1.42857;
  border-radius: 4px;
  background-color: rgb(29, 53, 87);
  border-color: rgb(29, 53, 87);
  color: white;
  box-sizing: border-box;
  border-width: 2px;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  min-width: 140px;
}

.custom-multiselect {
  display: flex;

  .multiselect__tags {
    width: 100%;
  }
}
</style>